import { render, staticRenderFns } from "./MentorMentorcastsPage.vue?vue&type=template&id=0b032b20"
import script from "./MentorMentorcastsPage.vue?vue&type=script&lang=ts"
export * from "./MentorMentorcastsPage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports