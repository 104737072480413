
import Vue from "vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import moment from "moment";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastData",
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    },
    isAvailable: Boolean,
    feedbackMentee: Boolean,
    overviewMentor: Boolean,
    isShare: Boolean,
    profileShare: {
      type: Boolean,
      required: false,
      default: false
    },
    isIcs: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      // Mentorcast information table data
      infoData: [
        {
          name: "start_date",
          label: (this as any).$t("mentorcastHero.infoDataDateLabel"),
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: (this as any).$t("mentorcastHero.infoDataTimeLabel"),
          icon: "time",
          value: null
        },
        {
          name: "seat_price",
          label: (this as any).$t("mentorcastHero.infoDataPricePerSeatLabel"),
          icon: "outline-paid",
          value: null
        },
        {
          name: "max_seats",
          label: (this as any).$t("mentorcastHero.infoDataNoOfSeatsLabel"),
          icon: "seat-block",
          value: null
        }
      ] as InformationData[],
      // Mentorcast buttons
      buttons: [
        {
          name: (this as any).$t("mentorcastHero.buttonsFeedbackBtn"),
          color: "outline-primary",
          isAvailable: this.feedbackMentee,
          action: () =>
            this.$router.push({
              path: `/mentorcasts/${this.mentorcast.uuid}/rating`
            })
        },
        {
          name: (this as any).$t("mentorcastHero.buttonsOverviewBtn"),
          color: "outline-primary",
          isAvailable: this.overviewMentor,
          action: () =>
            this.$router.push({
              path: `/mentorcasts/${this.mentorcast.uuid}/overview`
            })
        },
        {
          name: (this as any).$t("mentorcastHero.buttonsEnterBtn"),
          color: "primary",
          isAvailable: !this.isAvailable,
          action: () =>
            this.$router.push({
              path: `/mentorcasts/${this.mentorcast.uuid}`
            })
        },
        {
          name: (this as any).$t("mentorcastHero.buttonsJoinBtn"),
          color: "primary",
          isAvailable: this.isAvailable,
          action: () =>
            this.$router.push({
              path: `/mentorcasts/${this.mentorcast.uuid}`
            })
        }
      ],
      isProfileShare: this.profileShare ?? false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  },
  mounted(): void {
    // Find mentorcast information by name
    const start_in = `${this.mentorcast.start_date} ${this.mentorcast.start_time}`;

    for (const key of Object.keys(this.mentorcast)) {
      const index = this.infoData.findIndex(i => i.name === key);
      if (index > -1) {
        if (key === "start_date") {
          this.infoData[index].value = moment(start_in)
            .format("Do MMMM, yyyy")
            .toString();
        } else if (key === "start_time") {
          this.infoData[index].value = moment(
            this.mentorcast?.[key],
            "hh:mm:ss"
          ).format("hh:mm A");
          continue;
        } else if (key === "seat_price") {
          this.infoData[index].value = `$${
            this.mentorcast[key as keyof Mentorcast]
          }`;
        } else
          this.infoData[index].value = this.mentorcast[key as keyof Mentorcast];
      }
    }
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    share() {
      console.log("Share button was clicked!");
    }
  }
});
