
import Vue from "vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import { mapGetters } from "vuex";
import MentorcastsLoading from "@/components/Mentorcast/MentorcastsLoading.vue";
import ProfileDataLoading from "@/components/Profile/ProfileDataLoading.vue";

export default Vue.extend({
  name: "MentorMentorcastsPage",
  components: {
    MentorcastData,
    ProfileData,
    MentorcastsLoading,
    ProfileDataLoading
  },
  data() {
    return {
      loadingMentor: true,
      loadingMentorcasts: true,
      tabs: ["Upcoming", "Completed"],
      currentTab: "Upcoming",
      page: 1,
      data: [] as any,
      load_more: false
    };
  },
  computed: {
    ...mapGetters({
      mentor: "mentor/getMentor",
      mentorcasts: "mentor/getMentorcasts"
    })
  },
  mounted(): void {
    this.loadingMentor = true;
    this.$store
      .dispatch("mentor/GET_MENTOR", {
        id: this.$route.params.id
      })
      .then(() => {
        this.loadingMentor = false;
        this.getMentorcasts();
      });
  },
  methods: {
    getMentorcasts(page = 1) {
      this.loadingMentorcasts = true;
      this.$store
        .dispatch("mentor/GET_MENTORCASTS", {
          type: this.currentTab,
          page: this.page,
          id: this.$route.params.id
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
          this.loadingMentorcasts = false;
        });
    },
    onTab(tab: any) {
      this.data = [];
      this.currentTab = tab;
      this.getMentorcasts(this.page);
    },
    onLoadMore() {
      this.page++;
      this.getMentorcasts(this.page);
    }
  }
});
